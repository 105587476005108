import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"

const SocialsPage = () => (
  <Layout>
    <Seo title="Socials" />
    {/* <section className="container container--content socials">
      <div className="container__inner socials__inner">
        <header className="socials__header">
          {{ include('partial/image.twig', {
            'image': post.meta('profile_image'),
            'sizes': '10em',
            'class': 'socials__profile objFit'
          }) }}
          <a href="https://www.instagram.com/anotherharrietblog/" target="_blank" rel="noopener" className="socials__handle">@anotherharrietblog</a>
        </header>
        {% if post.meta('links') %}
          <ul className="socials__list socials-list">
            {% for link in post.meta('links') %}
              <li className="socials__link socials-link">
                {% include 'partial/button.twig' with {
                  'button': link.link,
                  'class': 'socials-link__button'
                }%}
              </li>
            {% endfor %}
          </ul>
        {% endif %}

      </div>
    </section> */}
  </Layout>
)

export default SocialsPage
